import { render, staticRenderFns } from "./ProductGoodsInfoModal.vue?vue&type=template&id=174147f7&scoped=true&"
import script from "./ProductGoodsInfoModal.vue?vue&type=script&lang=js&"
export * from "./ProductGoodsInfoModal.vue?vue&type=script&lang=js&"
import style0 from "./ProductGoodsInfoModal.vue?vue&type=style&index=0&id=174147f7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174147f7",
  null
  
)

export default component.exports